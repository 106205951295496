import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TicketMinimal } from 'Models/Tickets/TicketMinimal.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, take } from 'rxjs';
import { AuthenticationService } from 'Services/AuthenticationService';
import { DeviceDetectorService } from 'Services/DeviceDetector.service';
import { MainMenuService } from 'Services/MainMenuService';
import { SettingsService } from 'Services/SettingsService';
import { AnonymousPageBase } from "Shared/BaseClasses/AnonymousPageBase";
import { IQ_VALIDATOR_PATTERNS } from 'Shared/Components/Forms/Validation/ValidationPatterns.model';
import { AnonymousTicketService } from '../../Services/AnonymousTicket.service';

@Component({
    templateUrl: './TicketSearchByNumberAndPhone.component.html',
    styleUrls: ['./TicketSearchByNumberAndPhone.component.scss']
})
export class TicketSearchByNumberAndPhoneComponent extends AnonymousPageBase {
    resultNotFound: boolean = false;

    public Ticket: TicketMinimal;

    codes: string[];
    showCodes: boolean = false;
    showSearchOptions: boolean = true;

    isPhoneView: boolean = false;

    group: UntypedFormGroup = this._FormBuilder.group({
        TicketNumber: this._FormBuilder.control(null, [Validators.required]),
        PhoneNumber: this._FormBuilder.control(null, [Validators.required, Validators.pattern(IQ_VALIDATOR_PATTERNS.phonePattern)]),
        Code: this._FormBuilder.control(null, [Validators.required])//Should be hidden most of the time.
    });
    get ticketNumber() { return this.group.get("TicketNumber"); }
    get phoneNumber() { return this.group.get("PhoneNumber"); }
    get code() { return this.group.get("Code"); }

    constructor(private _AnonymousTicketService: AnonymousTicketService, private _FormBuilder: UntypedFormBuilder, mainMenuService: MainMenuService,
        private _Toastr: ToastrService, private deviceDetectorService: DeviceDetectorService, private _SettingsService: SettingsService,
        authenticationService: AuthenticationService, private _RecaptchaV3Service: ReCaptchaV3Service)
    {
        super(mainMenuService, authenticationService);

        this.isPhoneView = this.deviceDetectorService.IsPhone;

        this._AnonymousTicketService.GetAvailableCenters().subscribe(val => {
            this.codes = val;
            if (val.length === 1)
                this.code.setValue(val[0]);
            else
                this.showCodes = true;
        });
    }

    search() {
        this.Ticket = null;

        //  https://dev.to/rodrigokamada/adding-the-google-recaptcha-v3-to-an-angular-application-kge
        //  Don't know why but on mobile, this page is firing off an api call to google which is returning a 401.  Does not do that on desktop!
        //  Generate the captcha token and then include it in the api request.  The server will validate it.
        this._RecaptchaV3Service
            .execute("FindByTicketNumberAndPhone").pipe(take(1), mergeMap(token => {
                // Include the token in your form data or perform necessary actions
                //console.log('reCAPTCHA v3 Token:', token);
                return this._AnonymousTicketService.FindTicketByTicketNumberAndPhone(this.ticketNumber.value, this.phoneNumber.value, this.code.value, token)
            })).subscribe({
                next: ticket => {
                    //  Must set this for settings for the One Call to be applied
                    this._SettingsService.CurrentOneCallCenterCode = this.code.value;

                    this.Ticket = ticket;
                    this.showSearchOptions = this.isPhoneView ? false : true;
                },
                error: (error: HttpErrorResponse) => {
                    if (error.status === 404)
                        this.resultNotFound = true;
                    //  error toast (for 500 errors) is handled globally - don't show error here it will show 2 errors!
                }
            });
    }
}
