<div class="page-header" *ngIf="!isPhoneView">
    <div class="header-content">
        <div class="header-title-xx">Find Ticket</div>
    </div>

    <div>
        <!-- extra div to prevent the button from expanding to fill the parent -->
        <div class="iq-header-button-container">
            <iq-icon-button button-class="Header" icon="Login" title="Go to Login page" routerLink="/login">Login</iq-icon-button>
        </div>
    </div>
</div>
<div class="page-content" style="overflow: auto; display: flex; flex-direction: column;">
    <div>
        <div *ngIf="showSearchOptions; else hiddenSearch" [formGroup]="group" style="padding: 5px 15px; display: flex; flex: 0 1 auto; align-items: baseline">
            <div style="display:flex; flex-wrap:wrap">
                <mat-form-field style="max-width: 200px; margin-right: 10px;">
                    <mat-label>Ticket Number</mat-label>
                    <input iqDisableAutofill matInput formControlName="TicketNumber" type="search" />
                    <mat-error iqValidationMessage [control]="ticketNumber"></mat-error>
                </mat-form-field>

                <mat-form-field style="max-width: 200px; margin-right: 10px;">
                    <!-- AllowEmpty=true because IN insists on doing that for the primary phone number on a large number of their tickets!!!
                    Among other problems that causes, it defeats even the small amount of security we have here by prompting for the phone number... -->
                    <mat-label>Phone Number</mat-label>
                    <iq-phone formControlName="PhoneNumber" [AllowEmpty]="true" type="search"></iq-phone>
                    <mat-error iqValidationMessage [control]="phoneNumber"></mat-error>
                </mat-form-field>

                <mat-form-field style="max-width: 200px; margin-right: 10px; min-width: 180px;" *ngIf="showCodes">
                    <mat-label>Center Code</mat-label>
                    <mat-select formControlName="Code">
                        <mat-option *ngFor="let item of codes" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div style="display:flex; flex-wrap: wrap; column-gap: 20px">
                <!--<button mat-button color="primary" [disabled]="!group || !group.valid" (click)="search()">Find</button>-->
                <div>
                    <iq-icon-button button-class="Button" icon="Search" [disabled]="!group || !group.valid" (click)="search()">Search</iq-icon-button>
                </div>

                <div *ngIf="resultNotFound" class="red" style="align-content:center">
                    Ticket not found please check your information and try again.
                </div>
            </div>
        </div>

        <ng-template #hiddenSearch>
            <div style="width: 100%; display: flex; justify-content: flex-start; padding: 5px">
                <a class="link" (click)="showSearchOptions = true">Search Again</a>
            </div>
        </ng-template>
    </div>
    <mat-divider></mat-divider>
    <div style="width: 100%; height: 100%; overflow: auto;">
        <ticket-anon-simple-view style="min-width: 600px;" *ngIf="Ticket" [Ticket]="Ticket"></ticket-anon-simple-view>
    </div>
</div>
