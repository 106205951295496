import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TicketMinimal } from 'Models/Tickets/TicketMinimal.model';
import { SettingsService } from 'Services/SettingsService';

@Injectable({
    providedIn: 'root'
})
export class AnonymousTicketService {

    constructor(public http: HttpClient, public settingsService: SettingsService) {
    }

    public GetAvailableCenters(): Observable<string[]> {
        return this.http.get<string[]>(this.settingsService.ApiBaseUrl + "/Administration/OneCallCenter/GetCenterCodesOnServer");
    }

    //  ** PLEASE DON'T CREATE ROUTES THAT SAY "ANONYMOUS".  I'm sure a hacker would figure that out but why advertise it?

    public FindTicketByTicketNumberAndPhone(ticketNumber: string, phoneNumber: string, centerCode: string, captchaToken: string): Observable<TicketMinimal> {
        const request = { token: captchaToken };
        return this.http.post<TicketMinimal>(this.settingsService.ApiBaseUrl + "/Tickets/FindByTicketNumberAndPhone/" + centerCode + "/" + ticketNumber + "/" + phoneNumber, request);
    }

    public FindTicketByID(centerCode: string, ticketID: string): Observable<TicketMinimal> {
        return this.http.get<TicketMinimal>(this.settingsService.ApiBaseUrl + "/Tickets/FindByTicketID/" + centerCode + "/" + ticketID);
    }
}
